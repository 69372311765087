.custom-navbar {
  /* height: 50px; */
  background-color: var(--light-grey);
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px var(--dark-grey);
  margin-bottom: 5px;
}
.navbar-title {
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 12px;
}
div.dropdown-menu.show {
  background-color: var(--light-grey);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--dark-grey);
}
a.dropdown-item {
  color: var(--dark-text);
}
.custom-nav-link {
  color: var(--dark-text);
  padding-right: 35px;
  padding-left: 35px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-decoration: none;
}
.custom-nav-link-selected {
  background-color: var(--main-background);
  text-decoration: none;
  font-weight: bold;
}
.custom-nav-link:hover {
  background-color: var(--info-bg-color);
  text-decoration: none;
  color: var(--dark-text);
}
.nav-item {
  border-radius: 10px;
  margin-right: 10px;
  outline: none;
}
.nav-item:hover {
  background-color: var(--info-bg-color);
}
.nav-item {
  color: var(--dark-text);
  border-radius: 10px;
  margin-right: 10px;
  color: var(--dark-grey);
}
.username {
  color: var(--dark-text);
}
.header-logo {
  height: 30px;
  width: auto;
  border-radius: 5px;
}

.main-nav {
  padding: 5px;
  margin: 10px;
  border-radius: 15px;
  background-color: var(--main-background);
  font-size: 18px;
}
.main-nav-dropdown {
  position: absolute;
  top: -10;
  left: 10;
  background-color: var(--main-background);
  border: 2px solid var(--secondary);
  border-radius: 5px;
}

.nav-link {
  align-content: center;
  margin-right: 50px;
  margin-left: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  padding: 2px;
  border-radius: 5px;
  color: var(--light-text);
  text-decoration: none;
}
.nav-link:hover {
  text-decoration: underline;
  color: var(--light-text);
}
.nav-link.active {
  background-color: var(--secondary);
  text-decoration: underline;
}
.nav-link.active:hover {
  background-color: var(--secondary);
}
