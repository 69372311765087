:root {
  --light-grey: #e6e6e6;
  --medium-grey: #bfbfbf;
  --dark-grey: #999999;
  --light-text: #f2f2f2;
  --dark-text: #1a1a1a;
  --main-background: #000;
  --light-bg-color: #ccffd0;
  --minus10-light-bg-color: #99ffa2;
  --minus20-light-bg-color: #66ff73;
  --dark-bg-color: #003304;
  --minus10-dark-bg-color: #006609;
  --minus20-dark-bg-color: #00990d;
  --info-bg-color: #eeccff;
  --minus10-info-bg-color: #dd99ff;
  --minus20-info-bg-color: #cc66ff;
  --alert-bg-color: #e60000;
  --secondary: #831fe0;
  --main-bg-color: #831fe0;
  --muted-bg-color: #e6d2f9;
  --danger-bg-color: #cc0000;
  --minus10-danger-bg-color: #ff0000;
  --minus20-danger-bg-color: #ff3333;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  position: relative;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}
.text-center {
  text-align: center;
}

.dark-bg {
  background-color: var(--secondary);
}
.muted-bg {
  background-color: var(--info-bg-color);
}
.external-image {
  border-radius: 10px;
  border: 1px solid var(--info-bg-color);
}

.bg-light {
  background-color: var(--light-bg-color);
}
