.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-container {
  display: block;
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  padding: 5px;
  border-radius: 5px;
}
.radio-container:hover {
  background-color: var(--main-background);
}
.radio-container input:checked ~ .radio-button {
  background-color: var(--main-background);
}
.radio-button {
  position: absolute;
  top: 5;
  left: 25%;
  height: 22px;
  width: 22px;
  background-color: var(--light-grey);
  border-radius: 50%;
  border: 2px solid var(--dark-grey);
}
.radio-button:after {
  content: "";
  position: absolute;
  display: none;
}
.radio-container input:checked ~ .radio-button:after {
  display: block;
}
.radio-container .radio-button:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--dark-grey);
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 27px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: var(--medium-grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  border-radius: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch input:checked + .slider {
  background-color: var(--secondary);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--medium-grey);
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.custom-form-switch {
  margin-bottom: 5px;
}
.custom-select {
  position: relative;
  background-color: white;
  border: 1px solid var(--dark-grey);
  border-radius: 5px;
  width: 150px;
  height: 25px;
  cursor: pointer;
}
.custom-select.active {
  background-color: var(--main-background);
  border: 1px solid black;
}
.custom-select-caret {
  position: absolute;
  right: 5px;
}
.custom-select-caret.open {
  bottom: 7px;
}
.custom-select-caret.closed {
  bottom: 2px;
}
.custom-select-box {
  min-width: 100px;
  max-width: 200px;
  padding-left: 5px;
  margin-right: 25px;
  overflow: hidden;
}
.custom-select-dropdown {
  position: absolute;
  left: -5px;
  top: 24px;
  background-color: var(--light-grey);
  border: 1px solid var(--dark-grey);
  border-radius: 5px;
  z-index: 99;
  cursor: pointer;
  max-height: 200px;
  min-width: 100px;
  max-width: 200px;
  padding-top: 3px;
  padding-bottom: 3px;
  overflow-y: auto;
}
.custom-select-option {
  color: var(--dark-text);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  overflow-wrap: break-word;
}
.custom-select-option.selected {
  background-color: var(--main-background);
  color: var(--dark-text);
}
.custom-select-option:hover {
  background-color: var(--info-bg-color);
  color: var(--dark-text);
}
.custom-select-option.disabled {
  background-color: var(--light-grey);
  color: var(--dark-grey);
  cursor: default;
}
.custom-input {
  border-radius: 5px;
  border: 1px solid var(--dark-grey);
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
  width: 80%;
}
.custom-input:focus {
  background-color: var(--info-bg-color);
  border: 1px solid var(--secondary);
  color: var(--dark-text);
}
.custom-input-label {
  font-size: 14px;
}

.form-input-error {
  background-color: var(--alert-bg-color);
  color: var(--light-text);
  padding: 2px;
  margin-bottom: 5px;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 5px;
}
