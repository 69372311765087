.tooltip-wrapper {
  display: inline-block;
  position: relative;
}
.tooltip-wrapper:hover {
  cursor: help;
}
.tooltip-tip {
  position: absolute;
  border: 1px solid var(--main-bg-color);
  border-radius: 4px;
  padding: 6px;
  color: black;
  background: var(--light-bg-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
}
.tooltip-sm {
  width: 250px;
}
.tooltip-tip.bottom {
  left: 50%;
  top: 105%;
  transform: translateX(-50%);
}
.tooltip-tip.right {
  left: 105%;
  top: -6px;
}
.tooltip-tip.left {
  right: 105%;
  top: -6px;
}
.tooltip-tip.top {
  bottom: 105%;
  transform: translateX(-50%);
}
.tooltip-tip.top-left {
  bottom: 105%;
  right: 105%;
}
.tooltip-tip.top-right {
  bottom: 105%;
  left: 105%;
}
.tooltip-content-mini {
  inline-size: 150px;
  overflow-wrap: normal;
  background-color: blue;
}
