.faq-question {
  margin-left: 20px;
}
.light-text {
  color: var(--light-text);
}
.muted-text {
  color: var(--minus10-dark-bg-color);
}
.custom-unordered-list {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}
.standout-header {
  width: 75%;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  font-weight: bold;
  font-size: 1.5em;
  border-radius: 5px;
  background-color: var(--info-bg-color);
}
