.custom-alert {
  color: var(--dark-text);
  border-radius: 5px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: center;
}
.custom-alert.danger {
  background-color: var(--alert-bg-color);
  color: var(--light-text);
}
.custom-alert.success {
  background-color: var(--minus10-light-bg-color);
}
.custom-alert.warning {
  background-color: var(--info-bg-color);
}
.pop-box {
  border: 1px solid var(--secondary);
  border-radius: 5px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--minus10-light-bg-color);
  color: var(--dark-text);
  margin-top: 5px;
  margin-bottom: 5px;
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.page-numbers {
  margin-top: 8px;
  height: 50px;
  padding: 5px;
}
.page-navigation {
  margin-top: 5px;
  margin-left: 2px;
  margin-right: 2px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  font-size: 20px;
  display: inline;
  color: var(--dark-text);
  border: 2px solid var(--secondary);
  border-radius: 3px;
}
.page-navigation.selected {
  background-color: var(--secondary);
  color: var(--light-text);
}
.page-navigation.disabled {
  background-color: var(--light-grey);
  border: 1px solid var(--dark-text);
}
.mini-div-line {
  margin: 8px;
  margin-left: 50px;
  margin-right: 50px;
  border-top: 1px solid var(--minus10-info-bg-color);
}
.row {
  display: grid;
  grid-template-columns: (auto-fit, minmax(100px, 1fr));
}
.single-card {
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  box-shadow: 1px 1px var(--minus10-info-bg-color);
  background-color: var(--light-grey);
}
.single-card.light-bg {
  background-color: var(--light-text);
}
.single-card.selected {
  background-color: var(--info-bg-color);
}
.announcement {
  background-color: var(--minus10-light-bg-color);
}
